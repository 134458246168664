import { getProductImg } from '@/utils/normalize-helpers'

export const normalizeToTableProduct = (serverProduct) => {
  return {
    name: null,
    gtin: null,
    sku: null,
    type: null,
    group: {},
    brand: null,
    brand_line: null,
    updated_at: null,
    product_popularity: null,
    masters: [],
    ...serverProduct,
    id: serverProduct.product_id,
    img: getProductImg(serverProduct.image || serverProduct.img || null),
  }
}
