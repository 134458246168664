import dayjs from 'dayjs'

export const shortenAddress = (address) => {
  const words = address.split(',').map((word) => word.trim())
  const shortenedWords = words.slice(-3)
  return shortenedWords.join(', ')
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const ruDateFormat = 'DD.MM.YYYY'
export const yearMonthDayFormat = 'YYYY-MM-DD'

export const convertDate = (date, format = ruDateFormat) => {
  if (typeof date === 'number') {
    return dayjs.unix(date).format(format)
  }
  return dayjs(date).format(format)
}

export const isPhoneValid = (phone) => {
  if (!phone) return true
  const clearPhone = phone.replace(/[^+\d]/g, '')
  return clearPhone.length > 8
}

export const dateFormat = 'DD.MM.YYYY'

export const normalizePhone = (phone) => {
  return '+7' + phone.replace(/[^+\d]/g, '')
}
export const debounce = function (
  func,
  waitMilliseconds = 50,
  options = {
    isImmediate: false,
  }
) {
  let timeoutId

  const debouncedFunction = function (...args) {
    const context = this

    const doLater = function () {
      timeoutId = undefined
      if (!options.isImmediate) {
        func.apply(context, args)
      }
    }

    const shouldCallNow = options.isImmediate && timeoutId === undefined

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(doLater, waitMilliseconds)

    if (shouldCallNow) {
      func.apply(context, args)
    }
  }

  debouncedFunction.cancel = function () {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }
  }

  return debouncedFunction
}

export const filterWordNumForm = (n, wordForms, returnOnlyWord) => {
  n = Math.abs(n) % 100
  const n1 = n % 10
  if (wordForms) {
    if (n > 10 && n < 20) {
      return !returnOnlyWord ? n + ' ' + wordForms[2] : wordForms[2]
    }
    if (n1 > 1 && n1 < 5) {
      return !returnOnlyWord ? n + ' ' + wordForms[1] : wordForms[1]
    }
    if (n1 === 1) {
      return !returnOnlyWord ? n + ' ' + wordForms[0] : wordForms[0]
    }
    return !returnOnlyWord ? n + ' ' + wordForms[2] : wordForms[2]
  } else {
    return null
  }
}

export const makeNotificationSound = (volume) => {
  return new Promise((resolve, reject) => {
    volume = volume || 1.0

    try {
      const soundSource = '/notification-sound.mp3'
      const sound = new Audio(soundSource)
      sound.volume = volume
      sound.onended = () => {
        resolve()
      }
      sound.play().catch((err) => {
        reject(err)
      })
    } catch (error) {
      reject(error)
    }
  })
}

export const formatThousandSeparator = (a) => {
  const roundedNumber = Number.isInteger(a) ? a : Number(a).toFixed(1)
  return roundedNumber
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ')
}

export const createFormDataImage = (img, location) => {
  const file2Upload = new FormData()
  file2Upload.append('location', location)
  file2Upload.append('img', img)
  return file2Upload
}

export const createFormDataVideo = (video, location) => {
  const file2Upload = new FormData()
  file2Upload.append('location', location)
  file2Upload.append('content', video)
  return file2Upload
}

export const transliterate = (text) => {
  const map = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'kh',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ы: 'y',
    э: 'e',
    ю: 'yu',
    я: 'ya',
    ь: '',
    ъ: '',
  }

  const transliterated = text
    .replace(/"[^"]*"|'[^']*'/g, '')
    .toLowerCase()
    .split('')
    .map((symbol) => map[symbol] || symbol)
    .join('')
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]+/g, '')
    .replace(/^_+|_+$/g, '')

  return transliterated
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const lightenColor = (hex, percent = 30) => {
  hex = hex.replace(/^#/, '')

  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  r = Math.min(255, Math.floor(r + ((255 - r) * percent) / 100))
  g = Math.min(255, Math.floor(g + ((255 - g) * percent) / 100))
  b = Math.min(255, Math.floor(b + ((255 - b) * percent) / 100))

  const toHex = (n) => n.toString(16).padStart(2, '0')
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export const calculateDistance = (coord1, coord2) => {
  const R = 6371 // Радиус Земли в километрах
  const dLat = (coord2.lat - coord1.lat) * (Math.PI / 180) // Разница широт
  const dLng = (coord2.lng - coord1.lng) * (Math.PI / 180) // Разница долгот
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(coord1.lat * (Math.PI / 180)) *
      Math.cos(coord2.lat * (Math.PI / 180)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}
