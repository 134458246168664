export const changeTokenHue = (token, newHue = null) => {
  const [tokenName, tokenHue] = token.split('-')
  if (!newHue) {
    newHue = tokenHue + 100
  }
  return `${tokenName}-${newHue}`
}

export default {
  'primary-50': '#F2F1FF',
  'primary-100': '#DBDBFD',
  'primary-200': '#CACAFC',
  'primary-300': '#A6A6FA',
  'primary-400': '#8383F8',
  'primary-500': '#7171F7',
  'primary-600': '#5F5FF6',
  'primary-700': '#5050EF',
  'primary-800': '#2424EB',
  'primary-900': '#1313CA',

  'red-50': '#F9DADE',
  'red-100': '#F6C8CD',
  'red-200': '#F1A3AC',
  'red-300': '#EB7F8B',
  'red-400': '#E86C7B',
  'red-500': '#E55364',
  'red-600': '#E8505B',
  'red-700': '#D62A36',
  'red-800': '#B81824',
  'red-900': '#7B0A13',

  'neutral-25': '#FFFFFF',
  'neutral-50': '#F8FAFB',
  'neutral-100': '#F4F4F7',
  'neutral-200': '#E2E2E5',
  'neutral-300': '#CFD1D6',
  'neutral-400': '#BCBEC3',
  'neutral-500': '#B1B3BD',
  'neutral-600': '#95969E',
  'neutral-700': '#7D7E85',
  'neutral-800': '#606166',
  'neutral-900': '#303033',

  'green-50': '#E3F8F3',
  'green-100': '#DAF2ED',
  'green-200': '#C5EEE4',
  'green-300': '#A3DED0',
  'green-400': '#7AD0BB',
  'green-500': '#61CCB3',
  'green-600': '#39C3A2',
  'green-700': '#30A388',
  'green-800': '#27856F',
  'green-900': '#1C6151',

  'orange-50': '#FFF6ED',
  'orange-100': '#FFEFDD',
  'orange-200': '#FFD6A6',
  'orange-300': '#FFC28A',
  'orange-400': '#FDB26D',
  'orange-500': '#FDA758',
  'orange-600': '#FD9C43',
  'orange-700': '#F67803',
  'orange-800': '#D36602',
  'orange-900': '#8D4402',

  'blue-50': '#ECF4FF',
  'blue-100': '#DDECFF',
  'blue-200': '#C8DAFB',
  'blue-300': '#BED4FC',
  'blue-400': '#93B7F9',
  'blue-500': '#7DA9F8',
  'blue-600': '#3C7EF5',
  'blue-700': '#1C69F3',
  'blue-800': '#0A4BC1',
  'blue-900': '#073281',

  'pink-50': '#FBEBF5',
  'pink-100': '#FAC1E7',
  'pink-200': '#F28ED1',
  'pink-300': '#FB75CE',
  'pink-400': '#F562C4',
  'pink-500': '#FA5BC5',
  'pink-600': '#E549B1',
  'pink-700': '#D21994',
  'pink-800': '#A50E73',
  'pink-900': '#7A1759',

  'yandex-yellow': '#ffcc00',
  'yandex-red': '#ff0000',
  'yandex-black': '#000000',
}
