var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',_vm._g(_vm._b({class:{
    'pa-0': _vm.size,
    'rost-small-btn': _vm.small,
    'btn-fix-hover': _vm.isNeedFixHover,
  },style:({
    color: _vm.vutifyTextColor,
    'font-weight': _vm.fontWeight,
    '--rowColor': _vm.vutifyUnderlineRowColor,
    background: _vm.transparent && 'transparent',
  }),attrs:{"small":_vm.small,"depressed":"","outlined":_vm.outlined}},'v-btn',_vm.bindedAttrs,false),_vm.$listeners),[_vm._t("default"),_vm._v(" "),(_vm.rostIcon)?_c('rost-icon',{attrs:{"name":_vm.rostIcon,"size":_vm.iconSize}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }